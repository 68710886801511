// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-art-tsx": () => import("./../../../src/pages/art.tsx" /* webpackChunkName: "component---src-pages-art-tsx" */),
  "component---src-pages-code-tsx": () => import("./../../../src/pages/code.tsx" /* webpackChunkName: "component---src-pages-code-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-templates-art-template-tsx": () => import("./../../../src/templates/ArtTemplate.tsx" /* webpackChunkName: "component---src-templates-art-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-music-template-tsx": () => import("./../../../src/templates/MusicTemplate.tsx" /* webpackChunkName: "component---src-templates-music-template-tsx" */),
  "component---src-templates-tags-template-tsx": () => import("./../../../src/templates/TagsTemplate.tsx" /* webpackChunkName: "component---src-templates-tags-template-tsx" */)
}

